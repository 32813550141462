import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/Header";
import "../Styles/Cv.css";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import BackArrow from "../Components/BackArrow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAddressCard,faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default function Cv() {
  const navigate = useNavigate();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);

  const sectionsRef = useRef([]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const topColumn = document.querySelector(".top-column");
      const topColumnRect = topColumn.getBoundingClientRect();

      // Kolla om musen är utanför top-column
      if (
        !isMobile &&
        (event.clientY > topColumnRect.bottom ||
          event.clientX < topColumnRect.left ||
          event.clientX > topColumnRect.right)
      ) {
        setMousePosition({
          x: event.clientX,
          y: event.clientY,
        });
      }
    };

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", checkIsMobile);

    checkIsMobile(); // Initial check

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", checkIsMobile);
    };
  }, [isMobile]);

  const contactMe = () => {
    navigate("/contact-me");
  };


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    });



    // Lägg till alla sektioner som ska observeras
    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <BackArrow /> 

      <div className="cv-container">
      <div className="top-column">

          <div className="cv-header fade-in" ref={(el) => (sectionsRef.current[0] = el)}>
            <h1>Philip Jansson</h1>
            <h2>Web Developer</h2>
          </div>

 
          <div className="contacts fade-in" ref={(el) => (sectionsRef.current[1] = el)}>
            <span className="contact-item">
            <FontAwesomeIcon className="contact-icon" icon={faPhone}/>
          <h2>+46760-549046</h2>
          </span>
          <span className="contact-item">
          <FontAwesomeIcon icon={faPaperPlane} className="contact-icon" />
          <h2>philipjansson1027@hotmail.com</h2>
          </span>
          <span className="contact-item">
          <FontAwesomeIcon icon={faAddressCard} className="contact-icon" />
            <h2>Koopmansgatan 11A, Gothenburg </h2>
            </span>

          </div>
        </div>

        <div className="bottom-column">
          <section className="about-me fade-in" ref={(el) => (sectionsRef.current[2] = el)}>
            <div className="heading">
              <h1>Profile</h1>
            </div>

            <div className="cv-description">
              <p>
              Recently coming from Staria, where I worked as a NetSuite consultant, developing and implementing NetSuite for customers, and customizing it to meet their financial and logistical needs. I primarily used TypeScript, SuiteScript, and SuiteQL. Although I enjoyed the role, my probationary employment was unfortunately terminated due to a lack of incoming projects. I have worked in various areas throughout my career, which has provided me with extensive experience. I am always eager to dive into new challenges where needed and learn quickly. I'm enthusiastic about applying my skills to meaningful work and contributing to a team that values innovation and growth.
              </p>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[3] = el)}>
            <div className="heading">
              <h1>Experience</h1>
            </div>

            <div className="experience-item">
              <div className="item-heading">
                <h1>Systems Developer, Staria AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>August 2023 - January 2024</p>
              </div>

              <div className="item-description">
                <p>
                Customized and developed NetSuite to meet customers' financial and logistical needs using JavaScript, SuiteScript, and suiteQL. Enjoyed the role very much but unfortunately, my probationary employment was terminated due to a low volume of available projects.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[4] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Frontend Developer, Input Interiör AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>November 2022 - May 2023</p>
              </div>

              <div className="item-description">
                <p>
                20 weeks of internship where I developed a product configurator and an app for booking meetings and conference rooms.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[5] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Relining Technician, Tubus System AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>October 2019 - August 2021</p>
              </div>

              <div className="item-description">
                <p>
                Worked as a traveling relining technician, responsible for relining main pipes and foundation plates in various buildings.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[6] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Account Manager, Pinscher Media AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>February 2018 - April 2019</p>
              </div>

              <div className="item-description">
                <p>
                Worked as a B2B salesperson, selling advertisements in tabloids and online newspapers.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[7] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Field Sales Representative/Store Staff, Ad Bildelar, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>August 2013 - October 2016</p>
              </div>

              <div className="item-description">
                <p>
                Sold the Ad Bilverkstad concept to workshops and spare parts to both workshops and consumers.
                </p>
              </div>
            </div>
          </section>

          <section className="education fade-in" ref={(el) => (sectionsRef.current[8] = el)}>
            <div className="experience">
              <h1 className="education-heading">Education</h1>
            </div>

            <div className="experience-item">
              <div className="item-heading">
                <h1>Jensen Vocational Education, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>August 2021 - June 2023</p>
              </div>

              <div className="item-description">
                <p>
                I studied frontend development with a focus on web security. In addition to frontend, there was also a strong emphasis on backend in Node.js and building web applications with secure communication.
                </p>
              </div>
            </div>
          </section>

          <section className="skills fade-in" ref={(el) => (sectionsRef.current[9] = el)}>
            <div className="experience">
              <h1>Skills</h1>
            </div>

            <div className="skills-items">
              <div className="skills-item">
                <h2>HTML</h2>
              </div>
              <div className="skills-item">
                <h2>CSS</h2>
              </div>
              <div className="skills-item">
                <h2>Javascript</h2>
              </div>
              <div className="skills-item">
                <h2>Typescript</h2>
              </div>
              <div className="skills-item">
                <h2>React</h2>
              </div>
              <div className="skills-item">
                <h2>Vue</h2>
              </div>
              <div className="skills-item">
                <h2>Node.js</h2>
              </div>
              <div className="skills-item">
                <h2>Express</h2>
              </div>
              <div className="skills-item">
                <h2>Firebase</h2>
              </div>
              <div className="skills-item">
                <h2>MongoDB</h2>
              </div>
              <div className="skills-item">
                <h2>AWS S3</h2>
              </div>
              <div className="skills-item">
                <h2>SQL</h2>
              </div>
              <div className="skills-item">
                <h2>SQL Workbench</h2>
              </div>
              <div className="skills-item">
                <h2>Docker</h2>
              </div>
              <div className="skills-item">
                <h2>Git</h2>
              </div>
              <div className="skills-item">
                <h2>NetSuite</h2>
              </div>
              <div className="skills-item">
                <h2>Suitescript</h2>
              </div>
              <div className="skills-item">
                <h2>suiteQL</h2>
              </div>
              <div className="skills-item">
                <h2>OWASP</h2>
              </div>
              <div className="skills-item">
                <h2>Scrum & Kanban</h2>
              </div>
            </div>

            {isMobile && (
              <button className="fixed-button" onClick={contactMe}>
                Contact me!
              </button>
            )}
          </section>
        </div>
      </div>

      <Footer />

      {!isMobile && (
        <button
          className="follow-mouse-button"
          style={{ top: mousePosition.y, left: mousePosition.x }}
          onClick={contactMe}
        >
          Contact me!
        </button>
      )}
    </div>
  );
}
