import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './Pages/Main';
import Cv from "./Pages/Cv";
import ContactMe from './Pages/ContactMe';
import Snake from './Pages/Snake';
import MusicPlayer from './Pages/MusicPlayer';
import AboutMe from './Pages/AboutMe';
import Appleet from './Pages/Appleet';
import CookieConsent from './Components/CookieConsent';
import BootScreen from './Components/BootScreen';
import Admin from './Pages/Admin';
import Login from './Pages/Login';
import ProtectedRoute from './Components/ProtectedRoute';
import { useState, useEffect } from 'react';

function App() {
  // Initialisera isAuthenticated från localStorage
  const [isAuthenticated, setAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated') === 'true';
    setAuthenticated(storedAuth);
  }, []);

  return (
    <div className="App">
      <BootScreen />
      <CookieConsent />
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="cv" element={<Cv />} />
          <Route path="contact-me" element={<ContactMe />} />
          <Route path="snake" element={<Snake />} />
          <Route path="music-player" element={<MusicPlayer />} />
          <Route path="philip-wiki" element={<AboutMe />} />
          <Route path="appleet" element={<Appleet />} />
          <Route path="login" element={<Login setAuthenticated={setAuthenticated} />} />
          <Route path="admin" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Admin />
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
