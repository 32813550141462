import React from 'react'
import Desktop from '../Components/Desktop.js';
import Header from "../Components/Header.js";
import "../Styles/Main.css"


export default function Main() {
  return (

    <div>
    <div className="main-container">
        <Header/>
        <Desktop/>
    </div>
    </div>
  )
}
