import React, { useState, useEffect, useRef } from "react";
import "../Styles/Snake.css";
import Header from "../Components/Header";
import BackArrow from "../Components/BackArrow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 

const COLS = 20; // Antal kolumner
const ROWS = 20; // Antal rader
const SPEED = 200; // Hastigheten på spelet (lägre = snabbare)
const MOBILE_THRESHOLD = 768; // Breddtröskel för att identifiera mobil enhet

const Snake = () => {
  const [snake, setSnake] = useState([
    { x: 8, y: 7 },
    { x: 8, y: 8 },
    { x: 8, y: 9 },
  ]); // Startpositionen för ormen
  const [direction, setDirection] = useState("UP"); // Start riktning
  const [food, setFood] = useState(generateRandomPosition()); // Startposition för maten
  const [isGameOver, setIsGameOver] = useState(false); // Spelstatus
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_THRESHOLD
  ); // Kontrollera om det är mobil

  const gridRef = useRef();

  // Identifierar mobil enhet baserat på skärmbredd
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_THRESHOLD);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Startar spelet och kontrollerar spelet genom setInterval
  useEffect(() => {
    if (!isGameOver) {
      const intervalId = setInterval(moveSnake, SPEED);
      return () => clearInterval(intervalId);
    }
  }, [snake, direction, isGameOver]);

  // Lyssnar på användarens knapptryckningar för att ändra riktning
  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case "ArrowUp":
          if (direction !== "DOWN") setDirection("UP");
          break;
        case "ArrowDown":
          if (direction !== "UP") setDirection("DOWN");
          break;
        case "ArrowLeft":
          if (direction !== "RIGHT") setDirection("LEFT");
          break;
        case "ArrowRight":
          if (direction !== "LEFT") setDirection("RIGHT");
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [direction]);

  // Flyttar ormen baserat på nuvarande riktning
  const moveSnake = () => {
    const newSnake = [...snake];
    const head = newSnake[0];

    let newHead;
    switch (direction) {
      case "UP":
        newHead = { x: head.x, y: head.y - 1 };
        break;
      case "DOWN":
        newHead = { x: head.x, y: head.y + 1 };
        break;
      case "LEFT":
        newHead = { x: head.x - 1, y: head.y };
        break;
      case "RIGHT":
        newHead = { x: head.x + 1, y: head.y };
        break;
      default:
        return;
    }

    // Kolla kollisioner med väggar och sig själv
    if (
      newHead.x < 0 ||
      newHead.x >= COLS ||
      newHead.y < 0 ||
      newHead.y >= ROWS ||
      snake.some((segment) => segment.x === newHead.x && segment.y === newHead.y)
    ) {
      setIsGameOver(true);
      return;
    }

    newSnake.unshift(newHead);

    // Hantera när ormen äter maten
    if (newHead.x === food.x && newHead.y === food.y) {
      setFood(generateRandomPosition());
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
  };

  // Generera en slumpmässig position för maten
  function generateRandomPosition() {
    let newPos;
    do {
      newPos = {
        x: Math.floor(Math.random() * COLS),
        y: Math.floor(Math.random() * ROWS),
      };
    } while (snake.some((segment) => segment.x === newPos.x && segment.y === newPos.y));
    return newPos;
  }

  // Starta om spelet
  const restartGame = () => {
    setSnake([
      { x: 8, y: 7 },
      { x: 8, y: 8 },
      { x: 8, y: 9 },
    ]);
    setDirection("UP");
    setFood(generateRandomPosition());
    setIsGameOver(false);
  };

  const handleDirectionChange = (newDirection) => {
    switch (newDirection) {
      case "UP":
        if (direction !== "DOWN") setDirection("UP");
        break;
      case "DOWN":
        if (direction !== "UP") setDirection("DOWN");
        break;
      case "LEFT":
        if (direction !== "RIGHT") setDirection("LEFT");
        break;
      case "RIGHT":
        if (direction !== "LEFT") setDirection("RIGHT");
        break;
      default:
        break;
    }
  };

  return (
      <div>
                  <Header />
                  <BackArrow />
    <div className="snake-game">

      <div className="grid" ref={gridRef}>
        {[...Array(ROWS)].map((_, rowIndex) => (
          <div className="row" key={rowIndex}>
            {[...Array(COLS)].map((_, colIndex) => {
              const isSnake = snake.some(
                (segment) => segment.x === colIndex && segment.y === rowIndex
              );
              const isFood = food.x === colIndex && food.y === rowIndex;
              return (
                <div
                  key={colIndex}
                  className={`cell ${isSnake ? "snake" : ""} ${isFood ? "food" : ""}`}
                ></div>
              );
            })}
          </div>
        ))}
      </div>
      {isMobile   && !isGameOver  && (
        <div className="controls">
            <div className="up-down">
          <button onClick={() => handleDirectionChange("UP")} className="control-button">
          <FontAwesomeIcon icon={faArrowLeft} id="up-arrow"/>
          </button>
          </div>
          <div className="left-right">
            <button onClick={() => handleDirectionChange("LEFT")} className="control-button">
            <FontAwesomeIcon icon={faArrowLeft} id="left-arrow"/>
            </button>
            <button onClick={() => handleDirectionChange("RIGHT")} className="control-button">
            <FontAwesomeIcon icon={faArrowLeft} id="right-arrow"/>
            </button>
          </div>
          <div className="up-down">
          <button onClick={() => handleDirectionChange("DOWN")} className="control-button">
            <FontAwesomeIcon icon={faArrowLeft} id="down-arrow"/>
          </button>
          </div>
        </div>
      )}
      {isGameOver && (
        <div className="game-over">
          <p>Game Over!</p>
          <button onClick={restartGame}>Play Again</button>
        </div>
      )}
    </div>
    </div>
  );
};

export default Snake;
