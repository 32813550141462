import React from 'react'
import { Link } from 'react-router-dom'
import "../Styles/Appleet.css"
import Header from "../Components/Header.js"

export default function Appleet() {
  return (
      <div>
               <Header />
    <div className="appleet">
   
        <div className="appleet-header">
            <h2>Log in using these credentials:</h2>
        <p>Email: employer@mail.com</p>
        <p>Password: pwd123</p>
        <h2>Or register through Stripe <Link id="register-link" to="https://appleet.vercel.app/coach/register">here</Link></h2>
        <p>Use Card Number: 4242 4242 4242 4242</p>
        <p>Expiry Date: any future date</p>
        <p>CVC: any three digits</p>
        </div>
    <iframe className='appleet-iframe' src="https://appleet.vercel.app/login"  frameborder="0" allowfullscreen></iframe>
    </div>
    </div>
  )
}
