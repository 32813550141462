import React, {useState} from 'react'
import "../Styles/Desktop.css"
import { useNavigate, Link } from "react-router-dom";
import MusicPlayer from '../Pages/MusicPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphonesSimple } from '@fortawesome/free-solid-svg-icons';
import Chat from './Chat';

export default function Desktop() {
  const navigate = useNavigate()

  const lexBase = "https://www.lexbase.se/personsok?Search%5Bfullname%5D=philip+jansson&Search%5Bpersonal_id%5D=19931027&Search%5Bcity%5D=&_=&s=4de659c27844f4290fc0efae4de143f0"
  const faceBook = "https://www.facebook.com/philip.jansson.121"
  const linkedIn = "https://www.linkedin.com/in/philip-jansson-6a6107143/"
  const cv = () => {
    navigate("/cv")
  }
  const snake = () => {
    navigate("/snake")
  }

  const musicPlayer = () => {
    navigate("/music-player")
  }

  const philipWiki = () => {
    navigate("/philip-wiki")
  }

  const appleet = () => {
    navigate("/appleet")
  }

  
  return (
    <div className="desktop-container">
    {/*   <Chat /> */}
      <img src="/philip2.jpg" className="background-image" alt="background" />
      <div className="desktop-items">

      <div className="desktop-item" onClick={cv}>
          <img src="/philip2.jpg" alt="Philip" className="item-pic"/>
          <h1 className="item-text">CV</h1>
        </div>

        <div className="desktop-item" onClick={philipWiki}>
          <img src="/mondai.jpg" alt="Philip Wiki" className="item-pic"/>
          <h1 className="item-text">Philip Wiki</h1>
        </div>


     {/*    <div className="desktop-item" onClick={snake}>
          <img src="/snake.svg" alt="Philip" className="item-pic"/>
          <h1 className="item-text">Snake</h1>
        </div> */}

        <div className="desktop-item" onClick={musicPlayer}>
          <FontAwesomeIcon className="item-pic" icon={faHeadphonesSimple} id="headphones" />
          <h1 className="item-text">Music</h1>
        </div>

  {/*       <div className="desktop-item" onClick={musicPlayer}>
          <img src="/mondai.jpg" alt="Snake" className="item-pic"/>
          <h1 className="item-text">Weather</h1>
        </div>
 */}

{/*         <div className="desktop-item" onClick={musicPlayer}>
          <img src="/mondai.jpg" alt="Snake" className="item-pic"/>
          <h1 className="item-text">Calc</h1>
        </div>
 */}
        <div className="desktop-item" onClick={appleet}>
          <img src="/mondai.jpg" alt="Appleet" className="item-pic"/>
          <h1 className="item-text">Appleet</h1>
        </div>

        <Link className="desktop-item" to={linkedIn} target="_blank" rel="noopener noreferrer" >
          <img src="/linkedin.png" alt="LinkedIn" className="item-pic"/>
          <h1 className="item-text">LinkedIn</h1>
        </Link>

        <Link className="desktop-item" to={faceBook} target="_blank" rel="noopener noreferrer" >
          <img src="/facebook.png" alt="Facebook" className="item-pic"/>
          <h1 className="item-text">Facebook</h1>
        </Link>


        <Link className="desktop-item" to={lexBase} target="_blank" rel="noopener noreferrer" >
          <img src="/mondai.jpg" alt="Lexbase" className="item-pic"/>
          <h1 className="item-text">Lexbase</h1>
        </Link>

        </div>


        <div className="main-logo">
    {/*  */}
        </div>

    </div>
  )
}
