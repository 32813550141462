import React, { useState, useEffect } from 'react';
import "../Styles/CookieConsent.css";

export default function CookieConsent() {
    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'true') {
            setIsConsentGiven(true);
        } else {
            setShowBanner(true); 
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsConsentGiven(true);
        setShowBanner(false); // Dölj bannern

        setShowThankYou(true); // Visa "Thank you!"

        // Ta bort "Thank you!"-meddelandet efter 3 sekunder
        setTimeout(() => {
            setShowThankYou(false); // Dölj "Thank you!"
        }, 1500);
    };

    return (
        <div className="cookie-wrapper">
            <div className={`thank-you ${showThankYou ? 'show2' : ''}`}>
                <h1>Thank you!</h1>
            </div>
            <div className={`cookie-banner ${showBanner ? 'show1' : ''}`}>
                <p>I use cookies to improve your experience. By using my site, you agree to my use of cookies.</p>
                <div className="cookie-buttons">
                    <button onClick={handleAccept}>Okay!</button>
                </div>
            </div>
        </div>
    );
}
