import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login({ setAuthenticated }) {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (password === 'adminpassword') {  // Ersätt med ett bättre sätt för lösenordshantering
      localStorage.setItem('isAuthenticated', 'true'); // Spara i localStorage
      setAuthenticated(true);
      navigate('/admin');
    } else {
      alert('Wrong password!');
    }
  };

  return (
    <div>
      <h2>Admin Login</h2>
      <input 
        type="password" 
        placeholder="Enter Password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
}

export default Login;
