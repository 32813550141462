import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import AdminChat from "../Components/AdminChat"
import "../Styles/Admin.css"

function Admin() {
    const navigate = useNavigate()
    useEffect(() => {
        const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
        console.log(isAuthenticated)
        if (!isAuthenticated) {
          navigate('/'); // Omdirigera till inloggningssidan om användaren inte är autentiserad
        }
      }, []);
    
  return (
    <div>
        <AdminChat />
    </div>
  );
}

export default Admin;
