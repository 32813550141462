import {React, useState, useEffect }from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../Components/Header'
import "../Styles/ContactMe.css"
import BackArrow from '../Components/BackArrow'

export default function ContactMe() {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [placeholder, setPlaceholder] = useState(''); 

    useEffect(() => {
        const text = "Dear Philip, you're hired!";
        let index = 0;
    
        const interval = setInterval(() => {
          setPlaceholder(text.slice(0, index + 1)); 
          index++;
 
          if (index === text.length) {
            clearInterval(interval);
          }
        }, 150); 
    
     
        return () => clearInterval(interval);
      }, []);
    
  

    const handleChange = (event, setter) => {
        setter(event.target.value)
    }

    const sendMail = async (e) => {
        e.preventDefault();  
        if (email === "") return
    
        try {
          const response = await fetch('https://philipjanssondev-backend.vercel.app/send-email', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                from: email,
                 text: message,
                 phone: phone
                }), 
          });

         
    
          const result = await response.json(); // 
          if (response.ok) {
            console.log('E-post skickad framgångsrikt!'); 
            setEmail("")
            setPhone("")
            setMessage("")
            setPlaceholder("Dear Philip, you're hired! ")
            navigate("/")
          } else {
            console.log('Fel vid sändning av e-post: ' + result.error); 
          }
        } catch (error) {
          console.error('Nätverksfel:', error);
          console.log('Nätverksfel vid sändning av e-post. Försök igen senare.');
        }
      };

  return (
    <div className="wrapper">
        <Header />
{/*         <BackArrow /> */}

        <div className="form-wrapper">
            <div className="form-container">
            <label for="email">Your email (required)</label>
            <input className="form-input" type="email" name="email" placeholder="recruiter@google.com" value={email} onChange={(e) => handleChange(e, setEmail)} required/>
            <label  for="phone">Your phone number</label>
            <input className="form-input" type="text" name="phone" placeholder="+46760-123123" value={phone} onChange={(e) => handleChange(e, setPhone)}/>
 

            <label for="message">Message</label>
            <textarea className="form-message" rows="5" name="message" placeholder={placeholder} value={message} onChange={(e) => handleChange(e, setMessage)}></textarea>
            <button className="send-button" onClick={sendMail}>
                <img src="/plane.svg" alt="Send" className="plane-icon" />
                </button>

            </div>
        </div>
    </div>
  )
}
