import React, { useState, useEffect } from 'react';
import "../Styles/AdminChat.css";

export default function AdminChat() {
  const [chatList, setChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
    console.log(messages)
    console.log(chatList)
  useEffect(() => {
    fetchChatList();
  }, []);

  const fetchChatList = async () => {
    try {
      const response = await fetch('http://localhost:5000/chats');
      if (response.ok) {
        const data = await response.json();
        setChatList(data);
      } else {
        console.error('Failed to fetch chat list:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching chat list:', error);
    }
  };

  const fetchMessages = async (senderId) => {
    try {
      const response = await fetch(`/chats/${senderId}`);
      if (response.ok) {
        const data = await response.json();
        setMessages(data);
        setSelectedChat(senderId);
      } else {
        console.error('Failed to fetch messages:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const deleteChat = async (senderId) => {
    try {
      const response = await fetch(`/chats/${senderId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchChatList(); // Uppdatera chatlistan efter radering
        setMessages([]);
        setSelectedChat(null);
      } else {
        console.error('Failed to delete chat:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  return (
    <div className="admin-chat-container">
      <div className="chat-list">
        <h2>All Chats</h2>
        {chatList.map((chat) => (
          <div 
            key={chat.senderId} 
            className={`chat-item ${chat.senderId === selectedChat ? 'selected' : ''}`} 
            onClick={() => fetchMessages(chat.senderId)}
          >
            <p>{chat.senderName}</p>
            <button onClick={(e) => {e.stopPropagation(); deleteChat(chat.senderId);}}>Delete</button>
          </div>
        ))}
      </div>

      <div className="chat-window">
        {selectedChat ? (
          <>
            <h2>Chat with {selectedChat}</h2>
            <div className="messages">
              {messages.map((msg, index) => (
                <div key={index} className="message">
                  <p>{msg.text}</p>
                  <span>{new Date(msg.timestamp).toLocaleString()}</span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>Select a chat to view messages</p>
        )}
      </div>
    </div>
  );
}
