import React from 'react'
import "../Styles/BackArrow.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from "react-router-dom";


export default function BackArrow() {
    const navigate = useNavigate()
    const goBack = () => {
        navigate("/")
    }
  return (
    <div className="back-wrapper" onClick={goBack}>

        <FontAwesomeIcon id="back-arrow" icon={faArrowLeft} />
        <h2>cd..</h2>
    </div>
  )
}
