// BootScreen.js

import React, { useState, useEffect } from 'react';
import '../Styles/BootScreen.css';

const BootScreen = () => {
    const [showBootScreen, setShowBootScreen] = useState(null); // Använd null för att vänta på kontroll

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited');

        if (hasVisited) {
            // Om användaren har besökt tidigare, döljer boot screen omedelbart
            setShowBootScreen(false);
        } else {
            // Om det är första besöket, visa boot screen
            setShowBootScreen(true);

            const timer = setTimeout(() => {
                setShowBootScreen(false);
                localStorage.setItem('hasVisited', 'true'); // Spara att användaren har besökt sidan
            }, 3000); // 3000 ms = 3 sekunder

            // Rensa timeout om komponenten unmountas
            return () => clearTimeout(timer);
        }
    }, []);

    if (showBootScreen === null) {
        return null; // Rendera ingenting tills statusen är kontrollerad
    }

    return (
        <div className={`boot-screen ${showBootScreen ? '' : 'hide'}`}>
            <div className="loader"></div>
            <div className="message">Booting philipdevOS...</div>
        </div>
    );
};

export default BootScreen;
