import React, { useState, useRef, useEffect } from 'react';
import "../Styles/MusicPlayer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faForwardStep, faBackwardStep, faPause, faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import Header from '../Components/Header';
import BackArrow from "../Components/BackArrow.js"

export default function MusicPlayer() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [trackIndex, setTrackIndex] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [isLiked, setIsLiked] = useState(false);

    const audioRef = useRef(null);

    const tracks = [
        { title: "Ten", artist: "Mondai", src: "/Ten.wav", cover: "/ten-poster.jpg" },
        { title: "Hold On", artist: "Mondai", src: "/HoldOn.wav", cover: "/holdon-poster.jpg" }
    ];

    const togglePlay = () => {
        if (audioRef.current.paused) {
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleNext = () => {
        setTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    };
    
    const handlePrevious = () => {
        setTrackIndex((prevIndex) =>
            prevIndex === 0 ? tracks.length - 1 : prevIndex - 1
        );
    };

    const handleEnd = () => {
        handleNext(); // Gå till nästa låt när den nuvarande låten är slut
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleProgressChange = (e) => {
        const progress = e.target.value;
        audioRef.current.currentTime = progress;
        setCurrentTime(progress);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        audioRef.current.muted = !audioRef.current.muted;
    };

    const likeSong = () => {
        const likedSongs = JSON.parse(localStorage.getItem("likedSongs")) || {};
        const currentTrackTitle = tracks[trackIndex].title;

        if (isLiked) {
            delete likedSongs[currentTrackTitle];
        } else {
            likedSongs[currentTrackTitle] = true;
        }

        localStorage.setItem("likedSongs", JSON.stringify(likedSongs));
        setIsLiked(!isLiked);
    };

    useEffect(() => {
        const likedSongs = JSON.parse(localStorage.getItem("likedSongs")) || {};
        const currentTrackTitle = tracks[trackIndex].title;
        if (likedSongs[currentTrackTitle]) {
            setIsLiked(true);
        } else {
            setIsLiked(false);
        }
    }, [trackIndex]);

    useEffect(() => {
        // När trackIndex ändras, uppdatera ljudkällan och spela eller pausa som behövs
        if (audioRef.current) {
            audioRef.current.pause(); // Pausa den aktuella låten innan du byter spår
            setIsPlaying(false); // Sätt isPlaying till false tills nästa låt börjar spela
            // När nästa låt är vald, uppdatera ljudkälla och spela om isPlaying är true
            audioRef.current.src = tracks[trackIndex].src;
            audioRef.current.load();
            if (isPlaying) {
                audioRef.current.play();
                setIsPlaying(true);
            }
        }
    }, [trackIndex]);

    useEffect(() => {
        // Hantera uppspelning beroende på isPlaying
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    return (
        <div>
            <Header />
            <div className="music-player">
                <div className="tape-wrapper">
                    <div className="white-circle"></div>
                    <img
                        className={`track-label ${isPlaying ? 'rotating' : ''}`}
                        src={tracks[trackIndex].cover}
                        alt="Album cover"
                    />
                </div>

                <audio
                    ref={audioRef}
                    onEnded={handleEnd}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                />

                <div className="time-controls">
                    <div className="time">
                        <div className="track-info">
                            <span className="title-row">
                                <h2 className="track-title">{tracks[trackIndex].title}</h2>
                                <FontAwesomeIcon icon={isLiked ? faHeartSolid : faHeartRegular} className={`heart-icon ${isLiked ? "liked" : ""}`} onClick={likeSong} />
                            </span>
                            <h2 className="track-artist">{tracks[trackIndex].artist}</h2>
                        </div>
                        <input
                            type="range"
                            min="0"
                            max={duration}
                            value={currentTime}
                            onChange={handleProgressChange}
                            className="progress-bar"
                        />
                        <div className="time-row">
                            <span>{formatTime(currentTime)}</span>
                            <span>-{formatTime(duration - currentTime)}</span>
                        </div>
                    </div>
                </div>

                <div className="music-controls">
                    <button onClick={handlePrevious} className="previous-button">
                        <FontAwesomeIcon className="next-previous-icon" icon={faBackwardStep} />
                    </button>
                    <button onClick={togglePlay} className="play-button">
                        {isPlaying ? <FontAwesomeIcon className="play-pause-icon" icon={faPause} /> : <FontAwesomeIcon className="play-pause-icon" icon={faPlay} />}
                    </button>
                    <button onClick={handleNext} className="next-button">
                        <FontAwesomeIcon className="next-previous-icon" icon={faForwardStep} />
                    </button>
                </div>

                <div className="mute-wrapper">
                    {/* Uncomment and add mute functionality if needed */}
                    {/* <button onClick={toggleMute} className="mute-button">
                        {isMuted ? "Unmute" : "Mute"}
                    </button> */}
                </div>
            </div>
        </div>
    );
}
